.gpt3__possibility{
    display: flex;
    flex-direction: row
}

.gpt3__possibility-image{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 2rem;
}

.gpt3__possibility-image img{
    width:100% ;
    height:100%;
}

.gpt3__possibility-content{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    align-items: flex-start;
}

.gpt3__possibility-content h4{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71e5ff;
}
.gpt3__possibility-content h4:last-child{
    color: #ff7f50;
}
.gpt3__possibility-content h1{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 45px;
    margin: 1rem 0;
}
.gpt3__possibility-content p{
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 30px;
    color: var(--color-text);
    margin-bottom: 2rem;
}
 @media screen and (max-width:950px) {
    .gpt3__possibility{
        flex-direction: column;
    }
     .gpt3__possibility-image{
        margin: 1rem 0;
     }

     .gpt3__possibility-content{
        margin-top: 2rem;
     }
 }