.gpt3__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar);

}
.gpt3__cta-content p{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 12px;
    line-height: 30px;
    color: #0e0e0e;
    
}
.gpt3__cta-content h3{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 24px;
    line-height: 45px;
    color: #000000;
    
}
.gpt3__cta-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}
.gpt3__cta-btn button{
    background-color: #000000;
    color: #ffff;
    border-radius: 40px;
    font-family: var(--font-family);
    font-weight:bold;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
      
}

@media screen and (max-width: 650px) {
    .gpt3__cta{
        flex-direction: column;
    }
    .gpt3__cta-btn{
        margin: 2rem 0 0;
    }
}
@media screen and (max-width: 550px) {
    .gpt3__cta{
        margin: 4rem 2rem;
    }
    .gpt3__cta-content h3{
      font-size: 18px;
      line-height: 32px;
    }
    .gpt3__cta-btn button{
        font-size: 20px;
        line-height: 25px;
    }
}